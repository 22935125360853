@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: var(--font-family);
}

.navbar-header {
  position: var(--navbar-position);
  z-index: var(--navbar-zIndex);
  box-shadow: var(--navbar-box-shadow);
  height: var(--header-height);
}

.section-background {
  position: relative;
  background-color: var(--login-background);
  background: var(--login-background-image);
  background-size: cover;
  min-height: var(--login-background-min-height);
}

.language-icon-color {
  fill: var(--language-icon-color);
}

.language-icon-bg-color {
  fill: var(--language-icon-bg-color);
}

.login-list-box-style {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--login-card-box-color);
  border-radius: 6px;
  opacity: 1;
}

.login-list-box-style:hover {
  border-color: var(--login-card-box-hover-color);
}

.login-list-box-style:focus {
  border-color: var(--login-card-box-focus-color);
}

.brand-logo {
  content: var(--brand-logo-url);
  height: 40px;
}

.brand-only-logo {
  content: var(--brand-only-logo-url);
}

.background-logo {
  content: var(--background-logo-url);
}

.loading-indicator {
  background-color: var(--loading-indicator-bg-color);
  color: var(--loading-indicator-font-color);
}

.loading-indicator > svg {
  fill: var(--loading-indicator-ring-color);
}

.info-icon-button {
  color: var(--info-icon-color);
  position: relative;
  top: 2px;
}

.primary-button {
  color: var(--primary-button-color);
  background-color: var(--primary-button-bg-color);
  border: 1px solid var(--primary-button-border-color);
  border-radius: 5px;
}

.primary-button:disabled {
  cursor: not-allowed;
  color: var(--primary-button-disable-color);
  background-color: var(--primary-button-disable-bg-color);
  border-color: var(--primary-button-disable-border-color);
}

.primary-button:hover:not(:disabled) {
  color: var(--primary-button-hover-color);
  background-color: var(--primary-button-hover-bg-color);
  border-color: var(--primary-button-hover-border-color);
}

.secondary-button {
  color: var(--secondary-button-color);
  background-color: var(--secondary-button-bg-color);
  border: 1px solid var(--secondary-button-border-color);
  border-radius: 5px;
}

.secondary-button:disabled {
  cursor: not-allowed;
  color: var(--secondary-button-disable-color);
  background-color: var(--secondary-button-disable-bg-color);
  border-color: var(--secondary-button-disable-border-color);
}

.secondary-button:hover:not(:disabled) {
  color: var(--secondary-button-hover-color);
  background-color: var(--secondary-button-hover-bg-color);
  border-color: var(--secondary-button-hover-border-color);
}

.qrcode-border {
  border-color: var(--qrcode-border-color);
}

.slide-toggle-button {
  border-color: var(--toggle-button-inactive-border-color);
  background-color: var(--toggle-button-inactive-bg-color);
}

.slide-toggle-button::after {
  border-color: var(--toggle-button-inactive-ball-border-color);
  background-color: var(--toggle-button-inactive-ball-color);
}

.peer:focus ~ .toggle-outline {
  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width))
    var(--toggle-button-ring-color);
}

.peer:checked ~ .slide-toggle-button {
  border-color: var(--toggle-button-active-border-color);
  background-color: var(--toggle-button-active-bg-color);
}

.peer:checked ~ .slide-toggle-button::after {
  border-color: var(--toggle-button-active-ball-border-color);
  background-color: var(--toggle-button-active-ball-color);
}

.toggle-no-outline {
  height: 0.5rem;
  width: 2rem;
}

.toggle-no-outline::after {
  top: -0.25rem;
}

.toggle-outline {
  height: 1.25rem;
  width: 2.25rem;
}

.toggle-outline::after {
  top: 2px;
}

[dir="ltr"] .toggle-no-outline::after {
  left: 0px;
}

[dir="rtl"] .toggle-no-outline::after {
  right: 0px;
}

[dir="ltr"] .toggle-outline::after {
  left: 2px;
}

[dir="rtl"] .toggle-outline::after {
  right: 2px;
}

.footer-brand-logo {
  content: var(--footer-brand-logo-url);
  height: 25px;
}

.footer-container {
  padding-top: 11px;
  padding-bottom: 11px;
  color: var(--footer-text-color);
  font-size: var(--footer-text-size);
  background-color: var(--footer-bg-color);
  height: var(--footer-height);
}

.signup-banner {
  background-color: var(--signup-banner-background-color);
  border-radius: 0px 0px var(--signup-banner-bottom-radius)
    var(--signup-banner-bottom-radius);
  text-align: center;
  padding-top: var(--signup-banner-padding-top);
  padding-bottom: var(--signup-banner-padding-bottom);
  margin-top: var(--signup-banner-margin-top);
}

.signup-banner-text {
  font: normal normal normal 15px/19px "Kantumruy Pro";
  /* font-family: "Kantumruy Pro", sans-serif; */
  letter-spacing: 0px;
}

.signup-banner-hyperlink {
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0px;
  color: var(--signup-banner-signup-url-color);
}

.signup-banner-hyperlink:hover {
  color: var(--signup-banner-signup-url-color-hover);
}

.signup-banner-hyperlink:focus {
  color: var(--signup-banner-signup-url-color-focus);
}

.multipurpose-login-card {
  border-radius: var(--multipurpose-login-card-border-radius);
  background-color: var(--multipurpose-login-card-background-color);
  height: max-content;
}

.section-background .top_left_bg_logo,
.section-background .top_right_bg_logo,
.section-background .bottom_right_bg_logo,
.section-background .bottom_left_bg_logo {
  width: var(--side-section-bg-with);
  position: absolute;
}

.section-background .top_left_bg_logo {
  display: var(--top-left-bg-logo-display);
  content: var(--top-left-bg-logo-url);
  top: 10px;
  left: 10px;
}

.section-background .bottom_left_bg_logo {
  display: var(--bottom-left-bg-logo-display);
  content: var(--bottom-left-bg-logo-url);
  left: 10px;
  bottom: 10px;
}

.section-background .top_right_bg_logo {
  display: var(--top-right-bg-logo-display);
  content: var(--top-right-bg-logo-url);
  top: 10px;
  right: 10px;
}

.section-background .bottom_right_bg_logo {
  display: var(--bottom-right-bg-logo-display);
  content: var(--bottom-right-bg-logo-url);
  right: 10px;
  bottom: 10px;
}

@media screen and (max-width: 768px) {
  .section-background .top_left_bg_logo,
  .section-background .top_right_bg_logo,
  .section-background .bottom_right_bg_logo,
  .section-background .bottom_left_bg_logo {
    display: none;
  }
}

.client-logo-size {
  height: var(--login-client-logo-image-height);
  width: var(--login-client-logo-image-width);
}

.alternate-arrow {
  font-size: var(--login-alternate-arrow-size);
}

.alternate-arrow::after {
  content: var(--login-alternate-arrow);
}

.login-text {
  color: var(--login-text-color);
}

.login-card-separator {
  height: var(--login-card-separator-height);
  background-image: var(--login-card-separator);
}

.error-banner {
  color: #d52929;
  background-color: #faefef;
  height: 40px;
}

.error-banner-text {
  letter-spacing: 0px;
  opacity: 1;
}

.discontinue-button,
.discontinue-button:hover:not(:disabled) {
  border: 2px solid var(--primary-button-border-color);
  cursor: pointer;
}

.input-box {
  display: flex;
  align-items: center;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  overflow: hidden;
}

.input-box .prefix {
  letter-spacing: 0px;
  color: #171a1c;
  opacity: 0.6;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
}

[dir="ltr"] .input-box .prefix {
  padding-right: 10px;
  padding-left: 8px;
  border-right: 1px solid #707070;
}

[dir="rtl"] .input-box .prefix {
  padding-left: 10px;
  padding-right: 8px;
  border-left: 1px solid #707070;
}

[dir="ltr"] .prefix ~ input {
  padding-left: 10px;
}

[dir="rtl"] .prefix ~ input {
  padding-right: 10px;
}

.input-box input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding-left: 0rem;
  font: normal normal normal 16px/20px Inter;
}

[dir="ltr"] .input-box input {
  padding-left: 10px;
}

[dir="rtl"] .input-box input {
  padding-right: 10px;
}

.input-box:focus-within {
  border-color: #777;
}

.checkIcon {
  stroke: var(--language-check-icon-color);
}

.forgot-password-hyperlink {
  color: var(--forgot-password-color);
  font-size: var(--forgot-password-font-size);
  width: var(--forgot-password-width);
  margin-top: var(--forgot-password-margin-top) !important;
}

[dir="ltr"] .forgot-password-hyperlink {
  float: right;
}

[dir="rtl"] .forgot-password-hyperlink {
  float: left;
}

.password-google-reCaptcha {
  margin-top: var(--password-google-reCaptcha-margin-top) !important;
}

.errorInput {
  border: 1px solid #d52929;
}

/* Remove the spinner for the input type number */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

[data-radix-popper-content-wrapper] {
  z-index: 50 !important;
}

@media screen and (max-width: 767px) {
  .multipurpose-login-card {
    border-radius: 0;
  }

  .signup-banner {
    margin: 0.75rem;
    border-radius: 0;
  }

  .login-text .container {
    max-width: none;
  }

  .section-background .container {
    min-height: auto;
  }

  .react-tooltip {
    width: 97vw !important;
  }
}

@media screen and (min-width: 768px) {
  .section-background .container {
    min-height: var(--login-background-min-height);
  }
}

.error-page-header {
  color: #2c2c2c;
  font: normal normal bold 24px/29px Inter;
  text-align: center;
  letter-spacing: 0px;
  padding-top: 36px;
}

.error-page-detail {
  color: #7b7b7b;
  font: normal normal normal 14px/20px Inter;
  text-align: center;
  letter-spacing: 0px;
  padding-top: 10px;
}

@layer base {
  :root {
    --font-inter: var(--font-inter);
    --font-kantumruypro: var(--font-kantumruypro);

    --background: var(--background-color);
    --foreground: var(--foreground-color);

    --muted: var(--primary-muted);
    --muted-foreground: var(--primary-muted-foreground);
    --muted-neutral-gray: var(--primary-muted-neutral-gray);
    --muted-dark-gray: var(--primary-muted-dark-gray);
    --muted-light-gray: var(--primary-muted-light-gray);

    --popover: var(--primary-popover);
    --popover-foreground: var(--primary-popover-foreground);

    --border: var(--primary-border);
    --input: var(--primary-input);

    --card: var(--primary-card);
    --card-foreground: var(--primary-card-foreground);

    --step: var(--primary-step);
    --step-foreground: var(--primary-step-foreground);

    --primary: var(--primary-color);
    --primary-foreground: var(--primary-foreground-color);

    --secondary: var(--secondary-color);
    --secondary-foreground: var(--secondary-foreground-color);

    --accent: var(--accent-color);
    --accent-foreground: var(--accent-foreground-color);

    --destructive: var(--destructive-color);
    --destructive-foreground: var(--destructive-foreground-color);

    --ring: var(--ring-color);

    --radius: var(--radius-font-size);
  }

  .camdgc {
    --font-inter: var(--font-inter);
    --font-kantumruypro: var(--font-kantumruypro);

    --background: var(--background-color);
    --foreground: var(--foreground-color);

    --muted: var(--primary-muted);
    --muted-foreground: var(--primary-muted-foreground);
    --muted-neutral-gray: var(--primary-muted-neutral-gray);
    --muted-dark-gray: var(--primary-muted-dark-gray);
    --muted-light-gray: var(--primary-muted-light-gray);

    --popover: var(--primary-popover);
    --popover-foreground: var(--primary-popover-foreground);

    --border: var(--primary-border);
    --input: var(--primary-input);

    --card: var(--primary-card);
    --card-foreground: var(--primary-card-foreground);

    --step: var(--primary-step);
    --step-foreground: var(--primary-step-foreground);

    --primary: var(--primary-color);
    --primary-foreground: var(--primary-foreground-color);

    --secondary: var(--secondary-color);
    --secondary-foreground: var(--secondary-foreground-color);

    --accent: var(--accent-color);
    --accent-foreground: var(--accent-foreground-color);

    --destructive: var(--destructive-color);
    --destructive-foreground: var(--destructive-foreground-color);

    --ring: var(--ring-color);

    --radius: var(--radius-font-size);
  }
}
